/*oE (vsechny stranky)*/
$zahlavipozadi: #FFFFFF;
$zahlavitext: #272727;
$nazev: #114998;
$dodatek: #272727;
$zahlaviodkazy: #272727;
$vyhledavaniobrys: #B3D6FC;
 
$hlavninadpisy: #204998;
 
$menupozadi: #115898;
$menuzvyraznenipozadi: #FFFFFF;
$menutlacitkapozadi: #FEDF0F;
$menutlacitkatext: #000000;
$menuzvyraznenitext: #000000;
 
$upozornenipozadi: #D1E5FC;
$upozorneninadpisy: #114998;
$upozornenitext: #000000;
$upozorneniodkazy: #114998;
 
$aktuality1nadpisy: #2067A7;
$linky: #FEDF0F;
$bileplochyodkazy: #2067A7;
 
$deskalinky: #FEDF0F;
 
$kalendarakcipozadi: #D1E5FC;
$akcehlavninadpis: #204998;
$akcenadpisy: #204998;
$kalendarakcitext: #000000;
$akcedoplnkovytext: #000000;
$odkazyTabulekOstatni: #3C4146;
$odkazyTabulekAktualni: #FFFFFF;
$bunkyTabulekOstatni: #FFFFFF;
$bunkyTabulekAktualni: #115898;
$akcetlacitkapozadi: #FEDF0F;
$akcetlacitkatext: #000000;
$akceodkazy: #05297A;
 
$formularepozadi: #FFFFFF;
$formularetext: #272727;
$formularenadpis: #204998;
$polepozadi: #FFFFFF;
$poletext: #272727;
$poleobrysy: #B3D6FC;
 
$tlacitkapozadi: #FEDF0F;
$tlacitkatext: #000000;
 
$vysokapatapozadi: #115898;
$vysokapatatext: #FFFFFF;
$vysokapataodkazy: #FFFFFF;
$vysokapatanadpisy: #FFFFFF;
$vysokapatasymboly: #FEDF0F;
 
$patapozadi: #E8E8E2;
$patatext: #000000;
$pataodkazy: #114998;
